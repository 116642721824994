import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import { useSelector } from "react-redux";
import pluralize from "pluralize";
import api from "#utils/api";
import FeedCard from "#cards/FeedCard";
import CommentForm from "../forum/showPost/CommentForm";
import Comment from "../forum/showPost/Comment";
import { getMaterialIcon } from "#utils/icons";
const FavoriteBorderOutlinedIcon = getMaterialIcon("FavoriteBorderOutlined");
const FavoriteOutlinedIcon = getMaterialIcon("FavoriteOutlined");

const FullActivityItem = ({ activityItemId, setCardActivityItem, commentId }) => {
  const { currentUserId } = useSelector((state) => state.user);

  const { data: { activity_item: activityItem, comments }, refetch: refetchActivityItem } = useSuspenseQuery({
    queryKey: ["activityItem", activityItemId],
    queryFn: () => api.get(`/feed/activity_item/${activityItemId}${commentId ? `?highlightId=${commentId}` : ""}`),
    retry: false,
  });

  const handleCommentSuccess = (data) => {
    refetchActivityItem();
    setCardActivityItem && setCardActivityItem(data.resource);
  };

  const handleLike = (e) => {
    e.stopPropagation();
    api.post(`/feed/like`, { id: activityItem.id })
      .then(() => {
        refetchActivityItem();
        setCardActivityItem && setCardActivityItem((prevState) => ({ ...prevState, is_liked: true, upvotes: prevState.upvotes + 1 }));
      });
  };

  const handleRemoveLike = (e) => {
    e.stopPropagation();
    api.post(`/feed/remove_like`, { id: activityItem.id })
      .then(() => {
        refetchActivityItem();
        setCardActivityItem && setCardActivityItem((prevState) => ({ ...prevState, is_liked: false, upvotes: prevState.upvotes - 1 }));
      });
  };

  const renderLikeButton = () => {
    return (
      <div className="flex gap-1 self-start items-center justify-center rounded-default h-[26px] px-2.5 min-w-[51px] bg-transparent">
        <button
          className="flex justify-start items-center"
          onClick={activityItem.is_liked ? handleRemoveLike : handleLike}
          disabled={activityItem.user_id === currentUserId}
        >
          {activityItem.is_liked ? (
            <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
          ) : (
            <FavoriteBorderOutlinedIcon
              color="#D2D2D2"
              fontSize="small"
              className={`text-gray ${activityItem.user_id !== currentUserId && "hover:text-red"}`}
            />
          )}
        </button>
        <div className="text-xs text-center text-gray">{activityItem.upvotes}</div>
      </div>
    );
  };

  return (
    <div className="container max-w-[856px] m-auto text-left pt-1 md:p-2">
      <FeedCard activityItemData={activityItem} showReply={false} />
      <div className="flex justify-between items-center mt-4 md:mt-6 mb-1">
        {renderLikeButton()}
        <div className="text-gray text-xs">{pluralize("comments", activityItem.comment_count || 0, true)}</div>
      </div>
      {comments.length > 0 && (
        <div className="mb-4 md:mb-6">
          <hr className="text-gray" />
          {comments.map((c) => (
            <Comment key={c.uuid} commentData={c} highlightId={commentId} />
          ))}
        </div>
      )}
      <CommentForm resourceType="ActivityItem" resourceId={activityItemId} onSuccess={handleCommentSuccess} />
    </div>
  );
};

export default withFallback(FullActivityItem, undefined, () => <div>Whoops! We couldn't find that.</div>);
