export const timeAgo = (date) => {
  if (!date) return;
  const now = new Date();
  const createdAt = new Date(date);

  // Convert to Eastern Standard Time (EST)
  const estOffset = -5 * 60; // EST is UTC-5
  const nowEST = new Date(now.getTime() + (now.getTimezoneOffset() + estOffset) * 60000);
  const createdAtEST = new Date(createdAt.getTime() + (createdAt.getTimezoneOffset() + estOffset) * 60000);

  // if (createdAtEST.toDateString() === nowEST.toDateString()) {
  //   return "Today";
  // }
  const millisecondsAgo = nowEST - createdAtEST;
  const daysAgo = Math.floor(millisecondsAgo / (1000 * 60 * 60 * 24));
  const weeksAgo = Math.floor(daysAgo / 7);

  if (weeksAgo >= 1) {
    return `${weeksAgo}w`;
  } else if (daysAgo >= 1) {
    return `${daysAgo}d`;
  } else {
    const hoursAgo = Math.floor(millisecondsAgo / (1000 * 60 * 60));
    return hoursAgo === 0 ? "1h" : `${hoursAgo}h`;
  }
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const capitalize = (str) => {
  return str ? str[0].toUpperCase() + str.slice(1) : "";
};

// returns MM/DD/YY
export const formatDate = (date) => {
  const formatter = new Intl.DateTimeFormat("en-US", { dateStyle: "short" });
  return date && formatter.format(date instanceof Date ? date : new Date(date));
};
