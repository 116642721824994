import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function onHydrationEnd() {
  if (process.env.NODE_ENV !== "development") {
    datadogRum.init({
      applicationId: '8075d767-dc1f-4789-96d0-4a48ff18ed7b',
      clientToken: 'pubbe9847e787a059fcea0fa2fcfc22670d',
      site: 'datadoghq.com',
      service: 'pagebound-client',
      env: process.env.NODE_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogLogs.init({
      applicationId: '8075d767-dc1f-4789-96d0-4a48ff18ed7b',
      clientToken: 'pubbe9847e787a059fcea0fa2fcfc22670d',
      site: 'datadoghq.com',
      service: 'pagebound-client',
      env: process.env.NODE_ENV,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  } else {
    console.log("Would have initialized Datadog RUM outside of dev environment!");
  };
};
