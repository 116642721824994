import { useSuspenseQuery } from "@tanstack/react-query";
import api from "#utils/api";
import PointCard from "#cards/PointCard";
import Modal from "./Modal";

const ProfilePointsModal = () => {
  const { data: { point_awards: pointAwards, pending_points: pendingPoints } } = useSuspenseQuery({
    queryKey: ["profile", "pointAwards"],
    queryFn: () => api.get("/users/current/point_awards"),
  });

  return (
    <Modal isOpen title="Points">
      <div className="flex flex-col gap-3 max-w-[500px] mt-4">
        {pendingPoints.map((award, i) => (
          <PointCard key={i} pointAward={award} isPending />
        ))}
        {pointAwards.map((award) => (
          <PointCard key={award.id} pointAward={award} />
        ))}
      </div>
    </Modal>
  );
};

export default ProfilePointsModal;
