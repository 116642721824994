import React, { createElement, Fragment, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { ALGOLIA_CLIENT, indexName } from "#utils/algolia.jsx";
import BookCover from "./BookCover.jsx";

// Source: https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/#with-react-18
const AutocompleteBase = (props) => {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div id="algolia-autocomplete" ref={containerRef} />;
};

const BookResult = ({ hit, components }) => {
  return (
    <a
      href={`/books/${hit.uuid}`}
      className="aa-ItemLink p-2 hover:bg-mainGreen rounded-default hover:text-offBlack"
    >
      <div className="aa-ItemContent">
        <BookCover book={{ image_url: hit.image_url }} clickable={false} size="w-[66px] h-[100px]" />
        <div className="aa-ItemTitle flex flex-col gap-1">
          <b>
            <components.Highlight hit={hit} attribute="title" />
          </b>
          <p className="text-sm">
            by <components.Highlight hit={hit} attribute="author_name" />
          </p>
        </div>
      </div>
    </a>
  );
};

const Autocomplete = () => {
  function debouncePromise(fn, time) {
    let timerId = undefined;

    return function debounced(...args) {
      return new Promise((resolve) => {
        // If already waiting on pending invocation, cancel and restart timeout
        // If not, resolve right away for immediate feedback
        if (timerId) clearTimeout(timerId);
        else resolve(fn(...args));

        timerId = setTimeout(() => {
          // Clear timeout when function is invoked, so next call happens without another timeout
          timerId = undefined;
          resolve(fn(...args));
        }, time);
      });
    };
  };

  const debounced = debouncePromise((items) => Promise.resolve(items), 600);

  return (
    <AutocompleteBase
      openOnFocus={true}
      placeholder="Search Pagebound"
      onSubmit={({ state }) => {
        if (state.query === "" || state.collections.length === 0) return;
        const type = state.collections[0].items.length > 0 ? "Book" : "User";
        window.location.href = `/results?query=${state.query}&type=${type}`;
      }}
      classNames={{
        form: "rounded-full border border-offBlack focus-within:outline-none focus-within:ring-1 focus-within:border-seafoam focus-within:ring-seafoam",
        detachedSearchButton:
          "rounded-full border border-offBlack focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam",
        panel: "rounded-b-default shadow-main border z-20",
        input: "placeholder:text-gray",
        item: "p-0",
      }}
      getSources={({ query }) => {
        return debounced([
          {
            sourceId: "books",
            getItems() {
              // Override empty ranking logic, for now
              if (query === "") return [];
              return getAlgoliaResults({
                searchClient: ALGOLIA_CLIENT,
                queries: [
                  {
                    indexName,
                    query,
                  },
                ],
              });
            },
            getItemUrl({ item }) {
              return `/books/${item.uuid}`;
            },
            templates: {
              item({ item, components }) {
                return <BookResult hit={item} components={components} />;
              },
              footer({ items }) {
                if (items.length === 0) return null;
                return (
                  <a
                    href={`/results?query=${query}&type=User`}
                    className="flex flex-col text-center items-center justify-center gap-3 p-4"
                  >
                    <b>Not looking for books?</b>
                    <p>Click to view more results ✨</p>
                  </a>
                );
              },
              noResults() {
                if (query !== "") {
                  return (
                    <a
                      href={`/results?query=${query}&type=User`}
                      className="flex flex-col text-center items-center justify-center gap-3"
                    >
                      <b>No books found for "{query}"</b>
                      <p>Looking for something else? Click here ✨</p>
                    </a>
                  );
                } else {
                  return (
                    <div className="flex flex-col text-center items-center justify-center gap-3">
                      <b>Search for a book by title or author</b>
                      <p>Not looking for a book? Type your search term and hit Enter to view more options.</p>
                    </div>
                  );
                }
              },
            },
          },
        ]);
      }}
    />
  );
};

export default Autocomplete;
