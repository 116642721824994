import clsx from "clsx";
import React from "react";

const PointCard = ({ pointAward, isPending }) => {
  return (
    <a
      className={clsx("no-hover flex w-full p-4 md:p-4 gap-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between items-center",
        pointAward.link && "cursor-pointer",
        isPending && "opacity-80",
      )}
      href={pointAward.link}
    >
      <div className="flex flex-col gap-1">
        <p className="text-xs text-gray">{pointAward.date || "Not yet earned"}</p>
        <b>{pointAward.description}</b>
      </div>
      <b className={clsx(
        "flex flex-none w-[40px] h-[40px] rounded-full border justify-center items-center",
        isPending ? "bg-lightGray" : pointAward.points > 0 ? "bg-yellow" : "bg-offWhite",
        isPending && "opacity-80",
      )}>
        {pointAward.points > 0 ? "+" : ""}{pointAward.points}
      </b>
    </a>
  );
};

export default PointCard;
