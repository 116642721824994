import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "#utils/api.js";

export const completeOnboarding = createAsyncThunk(
  "onboarding/completeOnboarding",
  ({ onboardingData, showcasedBookIds }, { rejectWithValue }) =>
    api
      .post(`/users/complete_onboarding`, {
        user: onboardingData,
        showcased_book_ids: showcasedBookIds,
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data.error);
      })
);

const initialState = {
  showcasedBooks: [],
  username: null,
  bio: null,
  avatar: null,
  loading: false,
  error: null,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateOnboardingData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(completeOnboarding.fulfilled, (state, action) => {
      state = initialState;
    });
    builder.addCase(completeOnboarding.rejected, (state, action) => {
      return { ...state, error: action.payload, loading: false };
    });
    builder.addCase(completeOnboarding.pending, (state, action) => {
      return { ...state, loading: true };
    });
  },
});

export const { updateOnboardingData } = onboardingSlice.actions;
export default onboardingSlice.reducer;
