import React from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import Modal from "./Modal";
import api from "#utils/api";

const QuestSocialActivityModal = ({ quest }) => {
  return (
    <Modal isOpen>
      <div className="min-w-[200px] flex flex-col gap-3">
        <Activity quest={quest} />
      </div>
    </Modal>
  );
};

const Activity = withFallback(({ quest }) => {
  const { data: activity } = useSuspenseQuery({
    queryKey: ["questSocialActivity", quest.uuid],
    staleTime: 60 * 2 * 100, // 2 minutes
    queryFn: () => api.get(`/challenges/${quest.uuid}/social_activity`),
  });

  return (
    <div className="flex flex-col gap-3 mt-2">
      {activity.length === 0 ? (
        <div className="text-center">No activity yet!</div>
      ) : (
        activity?.map((item, i) => (
          <div key={i} className="flex flex-row gap-2 items-center">
            <div className="relative h-[30px] w-[30px]">
              <img
                src={item.user_image_url}
                alt={`${item.username}'s avatar`}
                className="rounded-full border border-offBlack absolute"
              />
            </div>
            <a href={`/users/${item.username}`}>{item.username}</a>
            {item.level && (
              <p className="text-xs md:text-sm">
                {quest.challenge_type == "Main Quest" ? "🏆" : "💎"}{" "}
                {item.level.charAt(0).toUpperCase() + item.level.slice(1)}
              </p>
            )}
          </div>
        ))
      )}
    </div>
  );
});

export default QuestSocialActivityModal;
