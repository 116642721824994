import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import api from "#utils/api";
import { closeModal, setModalDirty } from "#redux/slices/globalUISlice";
import NewPostForm from "../forum/NewPostForm";
import Modal from "./Modal";
import BookCover from "#helpers/BookCover";

const NewPostModal = ({ forum, isCurrentRead, onSubmit }) => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const timeOutRef = useRef(null);

  const onPostSubmit = (data) => {
    onSubmit && onSubmit(data);
    dispatch(setModalDirty(false));
    setShowSuccess(true);
    timeOutRef.current = setTimeout(() => {
      dispatch(closeModal());
    }, 1500);
  };

  useEffect(() => {
    return () => clearTimeout(timeOutRef.current);
  }, []);

  const onClickForm = () => {
    dispatch(setModalDirty(true));
  };

  return (
    <Modal
      isOpen
      title={
        isCurrentRead &&
        !showSuccess && (
          <div className="flex items-center gap-2">
            {forum.book_image && (
              <BookCover book={{ image_url: forum.book_image }} clickable={false} size="w-[54px] h-[80px]" />
            )}
            <span>New post in {forum.resource_type} forum</span>
          </div>
        )
      }
      classNames={{
        container: `${
          showSuccess ? "max-w-[500px]" : "min-w-[95%] md:min-w-[90%] lg:min-w-[700px] lg:max-w-[800px]"
        } !overflow-y-clipped`,
        margin: "mt-[30%] md:mt-[120px]",
      }}
      showActions={!showSuccess}
    >
      {showSuccess ? (
        <div className="flex flex-col items-center justify-center gap-4 p-2 text-center">
          <h2>Success! 🥳</h2>
          <p className="max-w-[500px]">Your update was added to the {forum.name} forum.</p>
        </div>
      ) : (
        <div onClick={onClickForm}>
          <NewPostForm forum={forum} onSubmit={onPostSubmit} isCurrentRead={isCurrentRead} />
        </div>
      )}
    </Modal>
  );
};

export default NewPostModal;
