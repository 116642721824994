import bookReducer from "./slices/bookSlice.js";
import userBookReducer from "./slices/userBookSlice.js";
import forumReducer from "./slices/forumSlice.js";
import userReducer from "./slices/userSlice.js";
import shelfReducer from "./slices/shelfSlice.js";
import clubReducer from "./slices/clubSlice.js";
import globalUIReducer from "./slices/globalUISlice.js";
import onboardingReducer from "./slices/onboardingSlice.js";

const reducers = {
  user: userReducer,
  book: bookReducer,
  userBook: userBookReducer,
  forum: forumReducer,
  shelf: shelfReducer,
  club: clubReducer,
  globalUI: globalUIReducer,
  onboarding: onboardingReducer,
};

export default reducers;
