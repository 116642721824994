export default function ChevronFilled({ fillColor, className, height, width }) {
  return (
    <svg
      className={className}
      fill="none"
      height={height || "12"}
      viewBox="0 0 13 12"
      width={width || "13"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m6.85547 11.6903c.09141-.0203.25137-.0812.35547-.132.14219-.0711.24121-.1473.39355-.2996l.20313-.2057c3.86188-6.7082 5.00958-8.7039 5.03748-8.7623.0305-.06094.0762-.19297.1041-.29199.0305-.10918.0508-.26406.0508-.37578 0-.11426-.0203-.2666-.0533-.38086-.0279-.1041-.0889-.264064-.1321-.35547-.0558-.114257-.1421-.228515-.2843-.368164-.1498-.152343-.2539-.228515-.3936-.299609-.1041-.050781-.2742-.111719-.3758-.1320312-.1599-.0330076-.8125-.0380869-5.33199-.0304689-4.92832.0050773-5.15176.007617-5.28633.0507811-.07617.027929-.218361.088867-.317385.139649-.114257.058398-.243749.157422-.355468.271679-.11172.111719-.213282.24375-.27168.355469-.050782.099023-.1167968.269145-.1472657.380855-.0279294.11426-.0507813.28438-.0507813.38848 0 .09902.0177736.25391.0406256.34277.022851.08633.0583982.19297.0761714.2336.017773.04316 1.162893 2.03633 2.544143 4.43066 1.38379 2.39434 2.56445 4.41793 2.62539 4.49413s.19043.2006.28691.2717c.11426.0863.25391.1625.39356.2133.17265.0635.2666.0812.46972.0888.16758.0051.30977-.0025.41895-.0279z"
        fill={fillColor}
      />
    </svg>
  );
}
