import React from "react";
import Badge from "#helpers/Badge";
import Modal from "./Modal";
import { useSelector } from "react-redux";

const BadgeModal = ({ badge }) => {
  const { currentUser } = useSelector((state) => state.user);
  const isTopContributor = badge.name == "Top Contributor" && currentUser?.is_top_contributor;

  return (
    <Modal isOpen classNames={{ root: "z-50" }}>
      <div className="flex flex-col gap-3 min-w-[290px] max-w-[320px] mt-3 items-center text-center">
        {badge.link ? (
          <a href={badge.link} className="text-[#004ABE]">
            <h2 className="font-bold">{badge.name}</h2>
          </a>
        ) : isTopContributor ? (
          <a href="/clubs/8dd91ad1-60fb-4364-adc5-9604c15f41af" className="text-[#004ABE]">
            <h2 className="font-bold">{badge.name}</h2>
          </a>
        ) : (
          <h2 className="font-bold">{badge.name}</h2>
        )}
        <p>{badge.description}</p>
        <Badge badge={badge} size="xl" />
        <p>Earned on {new Date(badge.earned_at).toLocaleDateString("en-US")}</p>
      </div>
    </Modal>
  );
};

export default BadgeModal;
