import algoliasearch from 'algoliasearch';
import { useInstantSearch } from 'react-instantsearch';

export const ALGOLIA_CLIENT = algoliasearch('F2DYZ2H7UF', import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY);

export const indexName = `Book_${process.env.NODE_ENV}`;

// Source: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/
export const ResultsBoundary = ({ children }) => {
  const { results, indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return (
      <div hidden>{children}</div>
    );
  };

  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        <p className="mt-4 text-center">Hmm, we didn't find anything for <q>{indexUiState.query}</q>.</p>
        <div hidden>{children}</div>
      </>
    );
  };

  return children;
}
