import React from "react";
import Modal from "./Modal";
import AddMissingBookForm from "./AddMissingBookForm";

const AddBookFromGoodreadsModal = () => {
  return (
    <Modal isOpen>
      <div className="max-w-[400px]">
        <AddMissingBookForm />
      </div>
    </Modal>
  );
};

export default AddBookFromGoodreadsModal;
