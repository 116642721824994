import { Tooltip } from "@mui/material";
import clsx from "clsx";
import { getMaterialIcon } from "#utils/icons";
const JoinRightRoundedIcon = getMaterialIcon("JoinRightRounded");

const Tag = ({ text, color, size = "small", IconComponent, tooltip }) => {
  const sizeClasses = size === "medium" ? "text-sm md:px-4" : "text-[10px] md:text-xs md:px-3";
  const tag = (
    <div
      className={clsx(
        "flex flex-none gap-1 justify-center items-center border border-offBlack px-2 py-1 h-fit rounded-default",
        color,
        sizeClasses
      )}
    >
      {text}
      {IconComponent && <IconComponent fontSize="inherit" />}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow enterTouchDelay={0} leaveTouchDelay={2000}>
        {tag}
      </Tooltip>
    );
  } else {
    return tag;
  };
};

export const OverlapTag = ({ percent = 0, showIcon = false, ...rest }) => {
  return (
    <Tag
      {...rest}
      text={`${percent || 0}%${showIcon ? "" : " overlap"}`}
      color="bg-yellow border-2 border-orange flex-row-reverse"
      IconComponent={showIcon && JoinRightRoundedIcon}
    />
  )
};

export const SpoilerTag = (props) => {
  return (
    <Tag {...props} text="spoilers" color="bg-seafoam" />
  )
};

export const DNFTag = (props) => {
  return (
    <Tag {...props} text="DNF" color="bg-pastelPink" />
  )
};

export default Tag;
