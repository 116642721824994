import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Hits, InstantSearch, SearchBox, Highlight, useInstantSearch } from "react-instantsearch";
import { ALGOLIA_CLIENT, indexName, ResultsBoundary } from "#utils/algolia.jsx";
import Modal from "./Modal.jsx";
import { closeModal } from "#redux/slices/globalUISlice";
import AddMissingBookForm from "./AddMissingBookForm.jsx";
import chevron from "#assets/chevron-left-circled.svg";
import BookCover from "#helpers/BookCover.jsx";

const SearchReturnBookModal = ({ handleClick, addedBookIds = [] }) => {
  const dispatch = useDispatch();
  const [showAddMissingBook, setShowAddMissingBook] = useState(false);
  const [showAlreadyAdded, setShowAlreadyAdded] = useState(false);
  const searchBoxRef = useRef(null);

  let timerId = undefined;
  let timeout = 600;

  function queryHook(query, search) {
    if (timerId) {
      clearTimeout(timerId);
    }

    if (showAlreadyAdded) {
      setShowAlreadyAdded(false);
    }

    timerId = setTimeout(() => search(query), timeout);
  }

  useEffect(() => {
    if (searchBoxRef.current) {
      searchBoxRef.current.focus();
    }
  }, []);

  const handleSelect = (book) => {
    if (addedBookIds && addedBookIds.includes(book.id)) {
      setShowAlreadyAdded(true);
      return;
    }

    handleClick(book);
    dispatch(closeModal());
  };

  const AddMissingBookButton = () => {
    const { indexUiState } = useInstantSearch();
    if (indexUiState.query)
      return (
        <button
          onClick={() => setShowAddMissingBook(true)}
          className="w-full h-8 rounded-default border border-gray text-xs mt-3 hover:text-pink"
        >
          Missing book?
        </button>
      );
  };

  return (
    <Modal
      isOpen
      headerActions={showAddMissingBook ? [{ icon: chevron, onClick: () => setShowAddMissingBook(false) }] : []}
    >
      <div className="md:w-[400px]">
        {showAddMissingBook ? (
          <AddMissingBookForm />
        ) : (
          <>
            <h2 className="mb-6">Search books</h2>
            <InstantSearch searchClient={ALGOLIA_CLIENT} indexName={indexName} scrollTo={false}>
              <SearchBox
                placeholder="Search by title or author"
                autoFocus
                inputRef={searchBoxRef}
                classNames={{
                  input:
                    "rounded-full text-base border border-offBlack focus-within:outline-none focus-within:ring-1 focus-within:border-seafoam focus-within:ring-seafoam placeholder:text-gray w-[300px]",
                }}
                queryHook={queryHook}
              />
              <ResultsBoundary>
                <Hits
                  classNames={{ item: "p-0 shadow-none" }}
                  // transformItems={(items) => items.filter((book) => !addedBookIds.includes(book.id))}
                  hitComponent={({ hit }) => (
                    <div
                      onClick={() => handleSelect(hit)}
                      className="flex gap-4 cursor-pointer w-full p-1 md:p-3 hover:bg-mainGreen rounded-default"
                    >
                      <BookCover
                        book={{ image_url: hit.image_url }}
                        clickable={false}
                        size="shrink-0 w-[47px] h-[70px] md:w-[66px] md:h-[100px]"
                      />
                      <div className="flex flex-col gap-1 justify-center">
                        <b className="text-sm md:text-base text-ellipsis line-clamp-3">
                          <Highlight hit={hit} attribute="title" />
                        </b>
                        <div className="text-xs md:text-sm">
                          by <Highlight hit={hit} attribute="author_name" />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </ResultsBoundary>
              <AddMissingBookButton />
              {showAlreadyAdded && <p className="text-center text-red mt-4">This book was already added!</p>}
            </InstantSearch>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SearchReturnBookModal;
