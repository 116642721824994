import React from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { timeAgo } from "#utils/utils";
import api from "#utils/api";

const NotificationCard = ({ notification, variant }) => {
  const queryClient = useQueryClient();
  const { currentUserId } = useSelector((state) => state.user);
  const NOTIFICATION_MAP = {
    new_club_member: {
      color: "bg-pastelBlue",
      preview: undefined,
    },
    new_pending_club_member: {
      color: "bg-pastelBlue",
      preview: undefined,
    },
    club_join_request_approved: {
      color: "bg-pastelBlue",
      preview: undefined,
    },
    new_club_post: {
      color: "bg-pastelBlue",
      preview: (
        <div className="flex w-full flex-col mt-2 bg-white rounded-default p-3 gap-1 items-start">
          <div className="flex gap-3 w-full">
            <p className="text-xs text-mainBlue font-bold">{notification.forum_name} forum</p>
          </div>
          <p className="text-ellipsis truncate w-full">{notification.body}</p>
        </div>
      ),
    },
    new_comment: {
      color: "bg-mainBlue",
      preview: (
        <div className="flex w-full flex-col mt-2 bg-white rounded-default p-3 gap-1 items-start">
          <div className="flex gap-3 w-full">
            {notification.forum_name && (
              <p className="text-xs text-mainBlue font-bold">{notification.forum_name} forum</p>
            )}
          </div>
          <p className="text-ellipsis truncate w-full">{notification.body}</p>
        </div>
      ),
    },
    comment_upvoted: {
      color: "bg-mainBlue",
      preview: (
        <div className="flex w-full flex-col mt-2 bg-white rounded-default p-3 gap-1 items-start">
          <div className="flex gap-3">
            {notification.forum_name && (
              <p className="text-xs text-mainBlue font-bold">{notification.forum_name} forum</p>
            )}
          </div>
          <p className="text-ellipsis truncate w-full">{notification.body}</p>
        </div>
      ),
    },
    post_upvoted: {
      color: "bg-mainBlue",
      preview: (
        <div className="flex w-full flex-col mt-2 bg-white rounded-default p-3 gap-1 items-start">
          <div className="flex gap-3">
            <p className="text-xs text-mainBlue font-bold">{notification.forum_name} forum</p>
          </div>
          <p className="text-ellipsis truncate w-full">{notification.body}</p>
        </div>
      ),
    },
    similar_book_upvoted: {
      color: "bg-pastelPink",
      preview: undefined,
    },
    new_follower: {
      color: "bg-pastelPurple",
      preview: undefined,
    },
    earned_badge: {
      color: "bg-pastelOrange",
      preview: undefined,
    },
    top_contributor: {
      color: "bg-pastelBlue",
      preview: undefined,
    },
    content_flagged: {
      color: "bg-orange",
      preview: undefined,
    },
    flag_approved: {
      color: "bg-orange",
      preview: undefined,
    },
    flag_rejected: {
      color: "bg-mainGreen",
      preview: undefined,
    },
  };

  const markAsRead = () => {
    api
      .post(`users/mark_notifications_as_read?notification_id=${notification.id}`)
      .then((data) => queryClient.setQueryData(["unreadNotifications", currentUserId], data));
  };

  if (variant == "modal") {
    return (
      <a
        href={notification.link}
        onClick={markAsRead}
        className="no-hover flex gap-2 items-center p-2 rounded-boxy cursor-pointer md:hover:bg-mainGreen"
      >
        {notification.image_url ? (
          <img
            src={notification.image_url}
            alt="avatar"
            className="w-[25px] h-[25px] border border-offBlack rounded-full"
          />
        ) : (
          <div
            className={`flex-none h-[25px] w-[25px] border border-offBlack ${
              NOTIFICATION_MAP[notification.action_type]?.color || "bg-pastelPink"
            } rounded-full`}
          />
        )}

        <div className="flex flex-col">
          <div className="text-xs text-gray">{timeAgo(notification.date)}</div>
          <div className="text-sm text-wrap">{notification.title}</div>
        </div>
      </a>
    );
  }

  return (
    <a
      href={notification.link}
      className="no-hover w-full flex gap-4 items-center p-2 rounded-boxy cursor-pointer md:hover:bg-mainGreen"
    >
      {notification.image_url ? (
        <img
          src={notification.image_url}
          alt="avatar"
          className="w-[20px] h-[20px] md:w-[30px] md:h-[30px] rounded-full border border-offBlack"
        />
      ) : (
        <div
          className={`relative flex-none border border-offBlack h-[20px] w-[20px] md:h-[30px] md:w-[30px] ${
            NOTIFICATION_MAP[notification.action_type]?.color || "bg-pastelPink"
          } rounded-full`}
        />
      )}
      <div className="flex flex-col items-start w-full text-left text-ellipsis truncate">
        <div className="text-xs text-gray">{timeAgo(notification.date)}</div>
        <p className="leading-tight text-wrap">{notification.title}</p>
        {NOTIFICATION_MAP[notification.action_type]?.preview}
      </div>
    </a>
  );
};

export default NotificationCard;
