import clsx from "clsx";
import React from "react";

const PlusMinusIcon = ({ isPlus, className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        "w-5 h-5 transition-transform duration-300 ease-in-out",
        { "transform rotate-90": isPlus },
        className
      )}
    >
      <circle cx="11" cy="11" r="10" stroke="#9C9C9C" strokeWidth=".9" fill="white" />
      {isPlus ? (
        <>
          <line x1="6" y1="11" x2="16" y2="11" stroke="#9C9C9C" strokeWidth=".9" />
          <line x1="11" y1="6" x2="11" y2="16" stroke="#9C9C9C" strokeWidth=".9" />
        </>
      ) : (
        <line x1="6" y1="11" x2="16" y2="11" stroke="#9C9C9C" strokeWidth=".9" />
      )}
    </svg>
  );
};

export default PlusMinusIcon;
