import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { Rating, Slider } from "@mui/material";
import { Formik, FieldArray, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setModalDirty } from "#redux/slices/globalUISlice";
import api from "#utils/api";
import Modal from "../modals/Modal";
import Switch from "#helpers/Switch";
import Button from "#components/helpers/Button";
import { getMaterialIcon } from "#utils/icons";
const StarRoundedIcon = getMaterialIcon("StarRounded");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");

const StarRating = ({ text, value, setValue }) => {
  const [hover, setHover] = useState(0);

  return (
    <div className="relative flex z-0 flex-row gap-2 items-center font-bold font-space">
      {text && <b className="w-[90px] md:w-[120px]">{`${text}: `}</b>}
      <div className="flex flex-row gap-2 items-center">
        <div className="relative">
          <Rating
            name={`${text.toLowerCase()}-rating`}
            value={Number(value ?? 0)}
            precision={0.5}
            size="medium"
            onChange={(e, newValue) => {
              setValue(newValue);
              setHover(newValue);
            }}
            onChangeActive={(event, newHover) => setHover(newHover)}
            icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="medium" />}
            emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="medium" />}
          />
          <div className="absolute md:hidden w-full h-full -top-[12px] left-0">
            <Slider
              value={Number(value ?? 0)}
              onChange={(e, newValue) => {
                setValue(newValue);
                setHover(newValue);
              }}
              step={0.5}
              min={0}
              max={5}
              track={false}
              sx={{
                width: "100%",
                height: "100%",
                color: "transparent",
                "& .MuiSlider-thumb": {
                  backgroundColor: "transparent",
                  boxShadow: "none !important",
                  "&::before": {
                    boxShadow: "none",
                  },
                  "&::after": {
                    boxShadow: "none",
                  },
                  "&:hover, &.Mui-focusVisible": {
                    boxShadow: "none",
                  },
                },
              }}
            />
          </div>
        </div>

        {(hover > 0 && hover) || value}
      </div>
    </div>
  );
};

const ReviewForm = ({ postSubmitHandler, userBook, review }) => {
  const dispatch = useDispatch();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const textareaRef = useRef(null);
  const [reviewText, setReviewText] = useState(""); // only used as a proxy for setting textarea height

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [reviewText]);

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const action = review
      ? api.put(`/reviews/${review.id}`, { review: { ...values, is_edited: true } })
      : api.post(`/reviews`, { review: values });

    action
      .then((data) => postSubmitHandler({ newReview: data }))
      .catch((error) => {
        setStatus({ error: "An unexpected error occurred. Please check your form and try again." });
        console.error("Error saving review: ", error);
      })
      .finally(() => setSubmitting(false));
  };

  const reviewSchema = Yup.object().shape({
    overall_rating: Yup.number().required("Required"),
    quality_rating: Yup.number().nullable(true),
    entertainment_rating: Yup.number().nullable(true),
    plot_rating: Yup.number().nullable(true),
    character_rating: Yup.number().nullable(true),
    is_spoiler: Yup.boolean(),
    is_dnf: Yup.boolean(),
    review: Yup.string().optional(),
    emojis: Yup.array().max(3, "Maximum 3 emojis"),
    user_book_id: Yup.number(),
    book_id: Yup.number(),
  });

  return (
    <Formik
      validationSchema={reviewSchema}
      validateOnMount={true}
      validateOnChange={true}
      onSubmit={handleSubmit}
      initialValues={{
        // hacky way to pass the selected star value from button click to the form
        overall_rating: userBook?.review?.overall_rating || review?.overall_rating || null,
        quality_rating: review?.quality_rating || null,
        entertainment_rating: review?.entertainment_rating || null,
        plot_rating: review?.plot_rating || null,
        character_rating: review?.character_rating || null,
        is_spoiler: review?.is_spoiler || false,
        review: review?.review || undefined,
        emojis: review?.emojis || [],
        book_id: review?.book_id || userBook?.book_id,
        user_book_id: userBook?.id,
        is_dnf: userBook?.status === "dnf",
      }}
    >
      {({ values, isSubmitting, isValid, status, setFieldValue }) => (
        <Form
          className="w-full m-auto flex flex-col items-start gap-6"
          onChange={() => dispatch(setModalDirty(true))}
        >
          <div className="flex flex-col gap-1">
            <StarRating text="Overall" value={values["overall_rating"]} setValue={(val) => setFieldValue("overall_rating", val)} />
            <StarRating text="Enjoyment" value={values["entertainment_rating"]} setValue={(val) => setFieldValue("entertainment_rating", val)} />
            <StarRating text="Quality" value={values["quality_rating"]} setValue={(val) => setFieldValue("quality_rating", val)} />
            <StarRating text="Characters" value={values["character_rating"]} setValue={(val) => setFieldValue("character_rating", val)} />
            <StarRating text="Plot" value={values["plot_rating"]} setValue={(val) => setFieldValue("plot_rating", val)} />
          </div>
          <div className="flex flex-col gap-1 w-full items-start">
            <p className="font-bold">Select up to 3 emojis</p>
            <FieldArray
              name="emojis"
              render={(arrayHelpers) => (
                <div className="flex gap-4 items-start">
                  {values.emojis?.map((emoji, index) => (
                    <div key={index} className="flex flex-col">
                      <div className="text-[25px]">{emoji}</div>
                      <button type="button" onClick={() => arrayHelpers.remove(index)}>
                        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                      </button>
                    </div>
                  ))}
                  {values.emojis?.length < 3 && (
                    <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
                      <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                    </button>
                  )}

                  <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
                    <EmojiPicker
                      skinTonesDisabled
                      lazyLoadEmojis
                      previewConfig={{ showPreview: false }}
                      onEmojiClick={(emojiData, event) => {
                        if (values.emojis.length < 3) arrayHelpers.push(emojiData.emoji);
                        setShowEmojiPicker(false);
                      }}
                    />
                  </Modal>
                </div>
              )}
            />
            <ErrorMessage name="emojis" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex flex-col gap-3 w-full items-start">
            <textarea
              minLength="2"
              ref={textareaRef}
              id="review-text"
              name="review"
              placeholder="Write your review! (optional)"
              value={values.review}
              onChange={(e) => setFieldValue("review", e.target.value) && setReviewText(e.target.value) && dispatch(setModalDirty(true))}
              className="py-2 px-3 min-h-20 w-full"
            />
            <ErrorMessage name="review" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex items-center">
            <label className="mr-4 font-bold" htmlFor="hasSpoilers">
              Does your post include spoilers?
            </label>
            <Switch id="hasSpoilers" isChecked={values.is_spoiler} onChange={() => setFieldValue("is_spoiler", !values.is_spoiler)} />
          </div>
          <div className="w-full flex justify-end items-center">
            {status && status.error && <div className="text-red mr-4">{status.error}</div>}
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              text={review ? "Update" : "Submit"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReviewForm;
