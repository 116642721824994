import React from "react";
import Button from "#helpers/Button";
import Modal from "./Modal";

const LibraryImportDirectionsModal = ({ source }) => {
  return (
    <Modal isOpen>
      <div className="md:max-w-[500px] p-2 flex flex-col gap-4">
        {source === "goodreads" ? (
          <>
            <b>To export your Goodreads data into a CSV file <i>(note: this works on desktop only)</i>...</b>
            <p>1. Go to <a href="https://www.goodreads.com/review/import" target="_blank">https://www.goodreads.com/review/import</a> and log into your Goodreads account (if you weren't already).</p>
            <p>2. Click the "Export Library" button at the top of the page, right below the Export heading.</p>
            <p>3. Wait a bit until a link "Your export from (date) - (time)" appears below the button. Click on that link to download the CSV file.</p>
            <p>4. Close this modal and upload the CSV file on the page below!</p>
            <Button text="Take me to Goodreads" type="link" to="https://www.goodreads.com/review/import" color="bg-neonBlue self-end" />
          </>
        ) : (
          <>
            <b>To export your Storygraph data into a CSV file...</b>
            <p>1. Go to <a href="https://app.thestorygraph.com/user-export" target="_blank">https://app.thestorygraph.com/user-export</a> and log into your Storygraph account (if you weren't already).</p>
            <p>2. Click the "Generate export" button.</p>
            <p>3. Wait a bit until you receive an email that your export is complete. Head back to the user export page to download the CSV file.</p>
            <p>4. Close this modal and upload the CSV file on the page below!</p>
            <Button text="Take me to Storygraph" type="link" to="https://app.thestorygraph.com/user-export" color="bg-neonBlue self-end" />
          </>
        )}
      </div>
    </Modal>
  );
};

export default LibraryImportDirectionsModal;
