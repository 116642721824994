import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "#utils/api.js";

export const fetchBook = createAsyncThunk("books/fetchBook", (uuid) => api.get(`/books/${uuid}`));

export const fetchGoodreadsData = createAsyncThunk("books/fetchGoodreadsData", (uuid) =>
  api.post(`/goodreads/fetch_data?uuid=${uuid}`)
);

const initialState = {
  currentBook: null,
  error: null,
  bookDataLoading: false,
};

export const bookSlice = createSlice({
  name: "book",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBook.fulfilled, (state, action) => {
      return {
        ...state,
        currentBook: action.payload.book,
        bookDataLoading: false,
      };
    });
    builder.addCase(fetchBook.rejected, (state, action) => {
      return { ...state, bookDataLoading: false, error: action.error.message };
    });
    builder.addCase(fetchBook.pending, (state) => {
      return { ...state, bookDataLoading: true };
    });
    builder.addCase(fetchGoodreadsData.fulfilled, (state, action) => {
      return {
        ...state,
        currentBook: {
          ...state.currentBook,
          image_url: action.payload.image_url,
          description: action.payload.description,
        },
      };
    });
  },
});

export default bookSlice.reducer;
