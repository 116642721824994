import React from "react";
import { closeModal } from "#redux/slices/globalUISlice";
import { useDispatch } from "react-redux";
import Button from "#helpers/Button";
import Modal from "./Modal";

const InfoModal = ({ title, body, buttonText }) => {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen classNames={{ root: "z-50" }}>
      <div className="flex flex-col gap-8 max-w-[500px] mt-3">
        <div className="flex flex-col gap-2">
          {title && <p className="font-bold">{title}</p>}
          {body && <p dangerouslySetInnerHTML={{ __html: body }} />}
        </div>
        <div className="flex gap-4 justify-end mt-2">
          <Button text={buttonText || "Got it"} color="bg-neonBlue" size="medium" onClick={handleConfirm} />
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
