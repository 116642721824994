import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalName: null,
  modalData: {},
  modalIsDirty: false,
  snackbarData: null,
  showListView: false,
};

export const globalUISlice = createSlice({
  name: "globalUI",
  initialState,
  reducers: {
    openModal: (state, action) => {
      if (action.payload.name) {
        state.modalName = action.payload.name;
        state.modalData = action.payload.data;
      } else {
        state.modalName = action.payload;
      }
      state.modalIsDirty = false;
    },
    closeModal: (state) => {
      state.modalName = null;
      state.modalIsDirty = false;
      state.modalData = {};
    },
    setModalDirty: (state, action) => {
      state.modalIsDirty = action.payload;
    },
    openSnackbar: (state, action) => {
      state.snackbarData = action.payload;
    },
    closeSnackbar: (state) => {
      state.snackbarData = null;
    },
    setShowListView: (state, action) => {
      state.showListView = action.payload;
    },
  },
});

export const { openModal, closeModal, setModalDirty, openSnackbar, closeSnackbar, setShowListView } = globalUISlice.actions;
export default globalUISlice.reducer;
