import React from "react";
import Badge from "#components/helpers/Badge";

const BadgeCard = ({ badge }) => {
  if (badge.link) {
    return (
      <a
        href={badge.link}
        className="no-hover relative w-full max-w-[800px] h-[106px] md:h-[122px] flex p-4 md:p-6 gap-4 bg-white border border-offBlack shadow-main rounded-default items-center"
      >
        <Badge badge={badge} size="large" />
        <div className="flex flex-col gap-1">
          <p className="font-bold">{badge.name}</p>
          <p className="text-xs md:text-sm leading-tight text-ellipsis line-clamp-2">{badge.description}</p>
        </div>
      </a>
    );
  }
  return (
    <div className="no-hover relative w-full max-w-[800px] h-[106px] md:h-[122px] flex p-4 md:p-6 gap-4 bg-white border border-offBlack rounded-default items-center">
      <Badge badge={badge} size="large" />
      <div className="flex flex-col gap-1">
        <p className="font-bold">{badge.name}</p>
        <p className="text-xs md:text-sm leading-tight text-ellipsis line-clamp-2">{badge.description}</p>
      </div>
    </div>
  );
};

export default BadgeCard;
