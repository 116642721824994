import { useSuspenseQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "vike/client/router";
import { withFallback } from "vike-react-query";
import { useGoogleLogin } from "@react-oauth/google";
import Menu from "#helpers/Menu.jsx";
import logo from "#assets/textLogo.svg";
import Squiggle from "#assets/Squiggle.jsx";
import Button from "#helpers/Button";
import Badge from "@mui/material/Badge";
import { signInCallback } from "#redux/slices/userSlice.js";
import Autocomplete from "#helpers/Autocomplete.jsx";
import NotificationCard from "#cards/NotificationCard.jsx";
import api from "#utils/api.js";
import { getMaterialIcon } from "#utils/icons";
const NotificationsRoundedIcon = getMaterialIcon("NotificationsRounded");
const MenuOutlinedIcon = getMaterialIcon("MenuOutlined");

const NavBar = () => {
  const dispatch = useDispatch();
  const { currentUserId, currentUser } = useSelector((state) => state.user);

  const { data: unreadNotifications } = useSuspenseQuery({
    queryKey: ["unreadNotifications", currentUserId],
    queryFn: () => api.get(`/users/notifications?type=unread`),
  });

  const handleLogin = useGoogleLogin({
    onSuccess: (result) => signInCallback(result, dispatch),
    flow: "auth-code",
  });

  const navItems = [
    { text: "Library", path: "/library" },
    { text: "Discover", path: "/discover" },
    { text: "Discuss", path: "/discuss" },
  ];

  return (
    <nav className="sticky top-0 bg-beige z-30 border-b-2 border-b-black w-screen">
      <ul className="relative h-[65px] flex lg:px-8 px-4 lg:gap-8 gap-3 text-md w-full items-center">
        {/* Logo, all users, mobile & desktop */}
        <li className="flex-none">
          <a href={currentUserId ? "/feed" : "/"} className="hidden lg:block">
            <img src={logo} alt="Logo" />
          </a>
          <a href={currentUserId ? "/feed" : "/"} className="lg:hidden">
            <Squiggle width="40 " height="44" />
          </a>
        </li>

        {/* Search box, all users, mobile & desktop */}
        <li className="w-full lg:w-[350px] xl:w-[400px]">
          <Autocomplete />
        </li>

        {/* Desktop primary nav items (both unauthed and authed users) */}
        {navItems.map(({ text, path }) => (
          <li key={text} className="hidden lg:block">
            <a className="font-bold" href={path}>
              {text}
            </a>
          </li>
        ))}

        <div className="flex flex-row gap-3 ml-auto">
          {/* Notification icon for authed users, both mobile and desktop */}
          {currentUserId && (
            <Menu
              IconComponent={
                <Badge badgeContent={unreadNotifications?.length} classes={{ badge: "border bg-fuschia" }}>
                  <NotificationsRoundedIcon />
                </Badge>
              }
              items={[
                {
                  content: (
                    <div className="flex justify-between items-center mb-3">
                      <b>Notifications</b>
                      <a href="/notifications" className="text-xs text-fuschia">
                        View all
                      </a>
                    </div>
                  ),
                },
                unreadNotifications?.length == 0 && { content: "No new notifications", preventClose: true },
                ...unreadNotifications?.map((notification) => ({
                  content: <NotificationCard notification={notification} variant="modal" />,
                })),
              ]}
              classes={{
                icon: "border shadow-main rounded-full w-[35px] h-[35px] flex-none",
                menu: "w-[350px] py-4 px-6",
              }}
            />
          )}

          {/* Desktop profile icon or login button */}
          <div className="hidden lg:flex ml-auto">
            {!currentUserId ? (
              <Button onClick={handleLogin} text="Log in" />
            ) : (
              currentUser?.image_url && (
                <img
                  src={currentUser?.image_url}
                  alt="My profile"
                  onClick={() => navigate("/profile")}
                  className="w-[35px] h-[35px] cursor-pointer shadow-main rounded-full border border-offBlack"
                />
              )
            )}
          </div>

          {/* Mobile nav menu dropdown, including profile link or login button */}
          <Menu
            IconComponent={<MenuOutlinedIcon fontSize="large" />}
            items={[
              ...navItems.map(({ text, path }) => ({ text: text, onClick: () => navigate(path) })),
              currentUserId
                ? {
                    text: "My Profile",
                    onClick: () => navigate("/profile"),
                  }
                : {
                    content: (
                      <div className="my-4 px-6">
                        <Button text="Log in" onClick={handleLogin} />
                      </div>
                    ),
                  },
            ]}
            classes={{
              icon: "lg:hidden bg-transparent",
              menu: "px-6",
              item: "text-base font-bold px-6 my-2",
            }}
          />
        </div>
      </ul>
    </nav>
  );
};

export default withFallback(NavBar);
