import React, { useState } from "react";
import { Menu as MuiMenu, MenuItem } from "@mui/material";
import { clientOnly } from "vike-react/clientOnly";
const MoreHorizOutlinedIcon = clientOnly(() => import("@mui/icons-material/MoreHorizOutlined"));

const Menu = ({ items, classes = {}, IconComponent, handleClose }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const filteredItems = items.filter((i) => i);

  const onItemClick = (event, callback) => {
    event.stopPropagation();
    callback && callback(event);
    setMenuAnchor(null);
  };

  if (filteredItems.length === 0) return;

  return (
    <>
      <button
        className={`my-auto self-center ${classes?.icon}`}
        onClick={(event) => {
          event.stopPropagation();
          setMenuAnchor(event.currentTarget);
        }}
      >
        {IconComponent || <MoreHorizOutlinedIcon fontSize="small" />}
      </button>
      <MuiMenu
        slotProps={{ paper: { className: `border p-2 !rounded-default !shadow-main ${classes?.menu}` } }}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={(event) => {
          event.stopPropagation();
          handleClose && handleClose();
          setMenuAnchor(null);
        }}
      >
        {filteredItems.map((item, i) => {
          if (item.content)
            return (
              <li key={i} onClick={() => !item.preventClose && setMenuAnchor(null)}>
                {item.content}
              </li>
            );
          return (
            <MenuItem
              key={i}
              dense
              disableRipple
              classes={{ root: `!rounded-default hover:!bg-mainGreen ${classes?.item}` }}
              onClick={(event) => onItemClick(event, item.onClick)}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
};

export default Menu;
