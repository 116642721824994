import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container mx-auto m-auto pt-8 md:pt-16 pb-[50px]">
          <header className="flex flex-col items-center m-auto gap-5 md:gap-6 mb-8 md:mb-16">
            <h1>Oops! Something went wrong.</h1>
            <h3>We've been notified of the issue and will look into it as soon as we can.</h3>
            <button
              onClick={() => window.location.href = "/"}
              className="bg-fuschia md:px-6 md:py-2 font-bold font-space text-center hover:text-black cursor-pointer border border-offBlack rounded-full shadow-main"
            >
              ⬅ Back to home
            </button>
            {process.env.NODE_ENV !== "production" && <pre>{this.state.error.stack}</pre>}
          </header>
        </div>
      );
    }

    return this.props.children;
  }
};