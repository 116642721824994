import React from "react";
import clsx from "clsx";

const Switch = ({ id, isChecked, onChange }) => {
  return (
    <label className="flex items-center cursor-pointer flex-none">
      <div className="relative flex flex-row gap-2 items-center">
        <input id={id} type="checkbox" className="hidden" checked={isChecked} onChange={onChange} />
        no
        <div
          className={clsx(
            "flex items-center border-offBlack border shadow-main justify-between cursor-pointer w-[44px] h-5 rounded-full relative transition-all",
            isChecked ? "bg-fuschia" : "bg-offWhite"
          )}
        >
          <div
            className={clsx(
              "absolute border border-offBlack transform w-[15px] h-[15px] bg-white rounded-full transition-all",
              isChecked && "left-2.5 translate-x-full",
              !isChecked && "left-0.5"
            )}
          ></div>
        </div>
        yes
      </div>
    </label>
  );
};

export default Switch;
