import React from "react";
import { openModal } from "#redux/slices/globalUISlice";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "vike/client/router";

const Badge = ({ badge, showTitle, size, clickable, onClick, noNav = false }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const isTopContributor = badge.name == "Top Contributor" && currentUser?.is_top_contributor;

  const BADGE_SIZE_MAP = {
    small: "w-[30px] h-[30px]",
    medium: "w-[40px] h-[40px]",
    large: "w-[60px] h-[60px]",
    xl: "w-[100px] h-[100px]",
  };

  const BADGE_INNER_IMG_SIZE_MAP = {
    small: "w-[23px] h-[23px]",
    medium: "w-[32px] h-[32px]",
    large: "w-[50px] h-[50px]",
    xl: "w-[90px] h-[90px]",
  };

  const CONTAINER_SIZE_MAP = {
    small: "w-[40px]",
    medium: "w-[50px]",
    large: "w-[70px]",
    xl: "w-[110px]",
  };

  const handleClick = () => {
    if (isTopContributor) {
      if (noNav) {
        return;
      }
      // open modal on achievements page
      return clickable
        ? dispatch(openModal({ name: "badgeInfo", data: { badge } }))
        : navigate("/clubs/8dd91ad1-60fb-4364-adc5-9604c15f41af");
    } else {
      return onClick ? onClick() : null;
    }
  };

  const showShadow = (onClick || isTopContributor) && !noNav;

  const badgeImage = () => {
    if (badge.level) {
      return (
        <div
          style={{
            backgroundImage: `url(/assets/badges/${badge.level}.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className={`${BADGE_SIZE_MAP[size]} ${
            showShadow ? "cursor-pointer shadow-main" : ""
          } flex flex-none items-center justify-center border rounded-full overflow-hidden`}
          onClick={handleClick}
        >
          <img
            src={`/assets/badges/${badge.image_url}.png`}
            alt={badge.name}
            className={`${BADGE_INNER_IMG_SIZE_MAP[size]}`}
          />
        </div>
      );
    } else {
      return (
        <img
          src={`/assets/badges/${badge.image_url}.png`}
          alt={badge.name}
          className={`${BADGE_SIZE_MAP[size]} ${
            showShadow ? "cursor-pointer shadow-main" : ""
          } flex flex-none border rounded-full`}
          onClick={handleClick}
        />
      );
    }
  };

  if (showTitle) {
    return (
      <div className={`flex flex-none flex-col items-center text-center text-wrap gap-2.5 ${CONTAINER_SIZE_MAP[size]}`}>
        {badgeImage()}
        {showTitle && <p className="text-xs max-w-full leading-tight">{badge.name}</p>}
      </div>
    );
  }

  return badgeImage();
};

export default Badge;
