import React from "react";
import { useSelector } from "react-redux";
import { capitalize } from "#utils/utils";
import BookCover from "#helpers/BookCover";
import { getMaterialIcon } from "#utils/icons";
const LockOutlinedIcon = getMaterialIcon("LockOutlined");

const ClubCard = ({ club, variant }) => {
  const { currentUser } = useSelector((state) => state.user);
  const currentUsersClub = club.creator_username === currentUser?.username;
  const statusCopy = currentUsersClub
    ? "Your Club"
    : club.member_status
    ? capitalize(club.member_status)
    : "Not Joined";

  if (variant === "small" || variant === "recommendation") {
    return (
      <a
        href={`/clubs/${club.uuid}`}
        className="no-hover relative w-full md:w-fit h-fit flex flex-col bg-white border border-offBlack shadow-main rounded-default p-4 md:p-6 gap-3 cursor-pointer items-start md:items-center"
      >
        {/* mobile style */}
        <div className="flex md:hidden flex-col gap-3 w-full text-left">
          <p className="font-bold text-ellipsis line-clamp-1 max-w-[220px]">{club.name}</p>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="text-xs md:text-s text-nowrap md:min-h-[20px]">
                {club.name !== "Pagebound Club" ? `${club.member_count} joined` : ""}
              </div>

              <div
                className={`flex items-center justify-center gap-1 text-[11px] md:text-[13px] leading-tight font-hanken border rounded-default w-full px-2 py-1 text-center ${
                  currentUsersClub ? "bg-pastelGreen" : club.member_status === "joined" ? "bg-pastelPurple" : "bg-beige"
                }`}
              >
                {statusCopy}
                {club.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
              </div>
            </div>
            <div className="flex gap-1">
              {club.emojis?.map((emoji, index) => (
                <div key={index} className="text-[20px]">
                  {emoji}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex md:grid md:grid-cols-2 gap-2 w-fit max-w-full md:min-h-[168px]">
          {club.book_details?.map((book, index) => (
            <BookCover key={index} book={book} size="w-[54px] h-[80px]" clickable={false} />
          ))}
        </div>
        <div className="hidden md:flex flex-col gap-2 flex-grow text-center max-w-full justify-between items-center">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-xs md:text-sm leading-none md:leading-tight w-[150px] text-ellipsis line-clamp-1">
              {club.name}
            </p>
            {variant !== "recommendation" && (
              <p className="leading-none text-xs md:text-sm md:min-h-[20px]">
                {club.name == "Pagebound Club" ? "" : `${club.member_count} joined`}
              </p>
            )}
          </div>

          <div
            className={`flex items-center justify-center gap-1 text-[11px] md:text-[13px] leading-none font-hanken border w-[125px] rounded-default p-1.5 text-center ${
              currentUsersClub ? "bg-pastelGreen" : club.member_status === "joined" ? "bg-pastelPurple" : "bg-beige"
            }`}
          >
            {statusCopy}
            {club.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
          </div>
        </div>
      </a>
    );
  }

  return (
    <div className="relative flex flex-col w-full md:max-w-[900px] p-4 md:p-6 gap-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between">
      <a
        href={`/clubs/${club.uuid}`}
        className="no-hover flex flex-col md:flex-row gap-2 md:gap-6 w-full justify-between cursor-pointer"
      >
        <div className="hidden md:flex flex-col gap-3 mr-2">
          <div className="flex gap-6 items-baseline">
            <p className="font-bold truncate">{club.name}</p>
            <div
              className={`flex items-center justify-center gap-1 text-[11px] md:text-[13px] leading-tight font-hanken border rounded-default px-4 py-1.5 text-center ${
                currentUsersClub ? "bg-pastelGreen" : club.member_status === "joined" ? "bg-pastelPurple" : "bg-beige"
              }`}
            >
              {statusCopy}
              {club.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
            </div>
          </div>
          {club.name !== "Pagebound Club" && <div className="text-xs md:text-s">{club.member_count} joined</div>}
          <p className="leading-tight">{club.description}</p>
        </div>

        {/* mobile style */}
        <div className="flex md:hidden flex-col gap-3">
          <p className="font-bold truncate">{club.name}</p>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              {club.name !== "Pagebound Club" && (
                <div className="text-xs md:text-s text-nowrap">{club.member_count} joined</div>
              )}
              <div
                className={`flex items-center justify-center gap-1 text-[11px] md:text-[13px] leading-tight font-hanken border rounded-default w-full px-2 py-1 text-center ${
                  currentUsersClub ? "bg-pastelGreen" : club.member_status === "joined" ? "bg-pastelPurple" : "bg-beige"
                }`}
              >
                {statusCopy}
                {club.private && <LockOutlinedIcon className="text-offBlack" fontSize="inherit" />}
              </div>
            </div>
            <div className="flex gap-1">
              {club.emojis?.map((emoji, index) => (
                <div key={index} className="text-[20px]">
                  {emoji}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 md:items-end shrink-0">
          <div className={`hidden md:flex w-full gap-3 justify-center ${club.book_details?.length <= 2 ? "mr-2" : ""}`}>
            {club.emojis?.map((emoji, index) => (
              <div key={index} className="text-[24px]">
                {emoji}
              </div>
            ))}
          </div>

          <div className="flex md:justify-between gap-2">
            {club.book_details?.map((book, index) => (
              <BookCover key={index} book={book} size="w-[54px] h-[80px]" clickable={false} />
            ))}
          </div>
        </div>
      </a>
    </div>
  );
};

export default ClubCard;
