import React from "react";
import clsx from "clsx";

const Loader = ({ variant, children, classes }) => {
  if (variant == "fullPage") {
    return (
      <div className="min-h-[100vh] h-max m-auto pt-8 flex items-center justify-center pb-[50px] md:pb-[100px]">
        <div className="relative flex flex-col w-full justify-center items-center">
          <div className="loadRow">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("relative flex w-full justify-center items-center", classes)}>
      <div className="loadRow">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {children}
    </div>
  );
};

export default Loader;
