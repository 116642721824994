import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import pluralize from "pluralize";
import Button from "#helpers/Button";
import api from "#utils/api";
import { openModal } from "#redux/slices/globalUISlice";
import BookCover from "#helpers/BookCover";
import Badge from "#helpers/Badge";
import { OverlapTag } from "#helpers/Tag";

const UserCard = ({ user, callback, variant, ActionOverride, isOnboarding }) => {
  const dispatch = useDispatch();
  const { currentUserId } = useSelector((state) => state.user);
  const [isFollowing, setIsFollowing] = useState(user.is_following);
  const isCurrentUser = currentUserId === user.id;

  useEffect(() => {
    setIsFollowing(user.is_following);
  }, [user]);

  // Note: only updates local state intentionally
  const handleFollow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFollowing(!isFollowing);
    if (!currentUserId) return dispatch(openModal("loginPrompt"));
    const action = isFollowing ? "unfollow" : "follow";
    api.post(`/users/${user.id}/${action}`);
  };

  if (variant === "showcase") {
    return (
      <a
        href={`/users/${user.username}`}
        className={`no-hover flex w-full p-3 md:p-6 gap-3 md:gap-6 text-left bg-white border border-offBlack ${
          !isOnboarding && "shadow-main"
        } rounded-default justify-between items-start lg:items-center cursor-pointer`}
        onClick={callback}
      >
        <div className="flex flex-col lg:flex-row gap-6 w-full">
          <div className="flex justify-between">
            <div className="flex gap-2 md:gap-6">
              <div className="relative w-[40px] h-[40px] md:w-[50px] md:h-[50px]">
                <div className="w-full h-full rounded-full absolute border border-offBlack" />
                {user.image_url && (
                  <img
                    src={user.image_url}
                    alt={`${user.username}'s avatar`}
                    className="w-full h-full rounded-full border border-offBlack absolute"
                  />
                )}
              </div>
              <div className="flex flex-col gap-1 md:gap-2 md:w-[160px]">
                <p>{user.username}</p>
                <div className="flex lg:flex-col items-center lg:items-start gap-2">
                  {!isOnboarding && <OverlapTag showIcon percent={user.user_overlap_percent} />}
                  <div className="flex items-center gap-1">
                    {user.showcased_badges.map((badge) => (
                      <Badge badge={badge} size="small" key={badge.id} noNav />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {!isCurrentUser && (
              <div className="self-start md:hidden">
                <Button
                  text={isFollowing ? "Unfollow" : "Follow"}
                  color={isFollowing ? "bg-mainGreen" : "bg-neonBlue"}
                  onClick={handleFollow}
                />
              </div>
            )}
          </div>
          <div className="flex gap-2 overflow-hidden md:overflow-visible w-full md:w-fit">
            {user.showcased_books.map((book) => (
              <BookCover book={book} clickable={false} size="w-[54px] h-[80px]" key={book.id} />
            ))}
          </div>
        </div>

        {!isCurrentUser && (
          <div className="mt-2 md:mt-0 hidden md:block">
            <Button
              text={isFollowing ? "Unfollow" : "Follow"}
              color={isFollowing ? "bg-mainGreen" : "bg-neonBlue"}
              onClick={handleFollow}
            />
          </div>
        )}
      </a>
    );
  }

  return (
    <a
      href={`/users/${user.username}`}
      className="no-hover flex w-full min-w-[300px] p-3 md:p-4 gap-3 md:gap-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between items-center cursor-pointer"
      onClick={callback}
    >
      <div className="flex gap-3 md:gap-6">
        <div className="relative w-[40px] h-[40px] md:w-[50px] md:h-[50px]">
          <div className="w-full h-full rounded-full absolute border border-offBlack" />
          {user.image_url && (
            <img
              src={user.image_url}
              alt={`${user.username}'s avatar`}
              className="w-full h-full rounded-full border border-offBlack absolute"
            />
          )}
        </div>

        <div className="flex flex-col justify-between">
          <p>{user.username}</p>
          <p className="text-xs">{pluralize("points", user.points, true)}</p>
        </div>
      </div>

      {ActionOverride
        ? ActionOverride
        : !isCurrentUser && (
            <Button
              text={isFollowing ? "Unfollow" : "Follow"}
              color={isFollowing ? "bg-mainGreen" : "bg-neonBlue"}
              onClick={handleFollow}
            />
          )}
    </a>
  );
};

export default UserCard;
